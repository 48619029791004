
export enum ActionsTypes {
    FANCY_GRID_SET_ALL_WITH_DEFAULTS = "FANCY_GRID_SET_ALL_WITH_DEFAULTS",
    FANCY_GRID_SET_DATA = "FANCY_GRID_SET_DATA",
    FANCY_GRID_SET_TOTAL = "FANCY_GRID_SET_TOTAL",
    FANCY_GRID_SET_PAGE_NUM = "FANCY_GRID_SET_PAGE_NUM",
    FANCY_GRID_SET_PAGE_SIZE = "FANCY_GRID_SET_PAGE_SIZE",
    FANCY_GRID_SET_SORT = "FANCY_GRID_SET_SORT",
    FANCY_GRID_SET_FILTER = "FANCY_GRID_SET_FILTER",
    FANCY_GRID_SET_IS_LOADING = "FANCY_GRID_SET_IS_LOADING"
}